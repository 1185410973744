const siteMetadata = {
    title: `Prateek Dubey`,
    siteUrl: `http://localhost`,
    capitalizeTitleOnHome: false,
    icon: `/images/icon.jpg`,
    logo: `/images/prateek_logo_1.png`,
    titleImage: `/images/wall.png`,
    ogImage: `/images/wall.png`,
    twoColumnWall: false,
    introTag: `AI/ML/DATA ENGINEERING, DATA GOVERNANCE, CLOUD COMPUTING, OPS (DEV, AI, ML, DATA), AI/ML/DATA PLATFORMS`,
    description: `Data Engineering Manager @Temus, Singapore`,
    about:
        "Data Engineering Manager at Temus, Singapore. Managing a team of Data Engineers to build AI and Data products across their clientele. I expertise in building solutions and services that bridge the gap between Data Engineering, Data Science, AI and Product. My work revolves around architecting, developing, and supporting Data, AI & ML Platforms, Data Engineering pipelines, Feature Engineering, Model Serving, and to provide Data Architecture solutions. I extensively work on Cloud, DevOps, Data Engineering, Data Governance, Data-Ops, AI-Ops and ML-Ops using Kubernetes, Docker, Kafka, Hadoop, Spark, Airflow, Terraform, AWS, Azure, and Cloudera",
    author: `prateek.dubey`,
    blogItemsPerPage: 9,
    darkmode: false,
    switchTheme: true,
    navLinks: [
        {
            name: "HOME",
            url: "/",
        },
        {
            name: "ABOUT",
            url: "/about",
        },
        // {
        //     name: "RESUME",
        //     url: "https://resume.prateekdubey.com",
        // },
        {
            name: "BLOG",
            url: "/blog",
        },
        {
            name: "CONTACT",
            url: "/contact",
        },
    ],
    footerLinks: [
        {
            name: "GitHub",
            url: "https://github.com/dprateek1991",
        },
        {
            name: "LinkedIn",
            url: "https://www.linkedin.com/in/prateekdubey11/",
        },
        {
            name: "Medium",
            url: "https://medium.com/@prateek.dubey",
        },
    ],
    social: [
        {
            name: "GitHub",
            icon: "/images/github.svg",
            url: "https://github.com/dprateek1991",
        },
        {
            name: "LinkedIn",
            icon: "/images/linkedin.svg",
            url: "https://www.linkedin.com/in/prateekdubey11/",
        },
        {
            name: "Medium",
            icon: "/images/medium.svg",
            url: "https://medium.com/@prateek.dubey",
        },
       {/* {
            name: "Youtube",
            icon: "/images/youtube.svg",
            url: "https://www.youtube.com/",
        } */}
    ],
    contact: {
        // leave empty ('') or false to hide form
        api_url: "https://getform.io/f/f227a36e-096a-4c6a-9963-9f1918a85bb3",
        description: "",
        mail: "reachme@prateekdubey.com",
        phone: "+65-93804965",
        address: "58 Bayshore Road, Singapore - 469981",
    },
    disqus: "prateekdubey-com",
}

const beforeContactFormSubmit = data => {
    // Code 0 - success
    // Code 1 - Name
    // Code 2 - Email
    // Code 3 - Message
    // Code 4 - Other
    const errors = []

    if (data.name.trim().length < 2) {
        errors.push({
            code: 1,
            message: "Enter a name",
        })
    }

    if (!data.email.match(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/)) {
        errors.push({
            code: 2,
            message: "Enter a valid email address",
        })
    }

    if (data.message.trim().length < 15) {
        errors.push({
            code: 3,
            message: "Enter a message with atleast 15 characters",
        })
    }

    if (errors.length > 0)
        return {
            result: false,
            errors: errors,
        }

    return {
        data: {
            name: data.name,
            email: data.email,
            message: data.message,
        },
        result: true,
    }
}

const contactFormSubmit = async (api, data) => {
    let res: any = await fetch(api, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })

    res = await res.json()

    if (res.success) {
        return {
            result: true,
        }
    }
    return {
        result: false,
        ...res,
    }
}

const defaults = {
    disqus: null,
    twoColumnWall: true,
    darkmode: false,
    switchTheme: true,
    capitalizeTitleOnHome: true,
}

Object.keys(defaults).forEach(item => {
    if (siteMetadata[item] === undefined) {
        siteMetadata[item] = defaults[item]
    }
})

export { siteMetadata, beforeContactFormSubmit, contactFormSubmit }